// Generated by Framer (20caf11)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, getLoadingLazyAtYPosition, Image, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/x_P53_09t";

const enabledGestures = {onbWSpzt3: {hover: true}};

const serializationHash = "framer-rgwB4"

const variantClassNames = {onbWSpzt3: "framer-v-1a30vx0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.1, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, text, width, ...props}) => { return {...props, orsSeNoum: text ?? props.orsSeNoum ?? "Explore all services", pU9NaLDBf: link ?? props.pU9NaLDBf} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, orsSeNoum, pU9NaLDBf, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "onbWSpzt3", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={pU9NaLDBf}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1a30vx0", className, classNames)} framer-clh08f`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"onbWSpzt3"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, ...style}} {...addPropertyOverrides({"onbWSpzt3-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1vhaj16"} data-styles-preset={"x_P53_09t"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-aed0a6d4-81a3-4db6-8060-7206b0ec1baf, rgb(31, 31, 31)))"}}>Explore all services</motion.p></React.Fragment>} className={"framer-g34zgk"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"cRnPcfLWp"} style={{"--extracted-r6o4lv": "var(--token-aed0a6d4-81a3-4db6-8060-7206b0ec1baf, rgb(31, 31, 31))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={orsSeNoum} verticalAlignment={"top"} withExternalLayout/><Image background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + (7 + ((((componentViewport?.height || 64) - 14) - 30) / 2)))), pixelHeight: 800, pixelWidth: 800, src: "https://framerusercontent.com/images/Z119xbYEBaXdu8LMZvmRSCWGRas.svg"}} className={"framer-bnald5"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"cdrAKHIQ_"}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rgwB4.framer-clh08f, .framer-rgwB4 .framer-clh08f { display: block; }", ".framer-rgwB4.framer-1a30vx0 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; height: 64px; justify-content: space-between; overflow: hidden; padding: 7px 20px 7px 26px; position: relative; text-decoration: none; width: 280px; will-change: var(--framer-will-change-override, transform); }", ".framer-rgwB4 .framer-g34zgk { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-rgwB4 .framer-bnald5 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 30px); overflow: hidden; position: relative; width: 30px; }", ".framer-rgwB4.framer-v-1a30vx0.hover.framer-1a30vx0 { padding: 7px 32px 7px 32px; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 280
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Z0jCTVbuU":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"orsSeNoum":"text","pU9NaLDBf":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerBcQdZ8fGO: React.ComponentType<Props> = withCSS(Component, css, "framer-rgwB4") as typeof Component;
export default FramerBcQdZ8fGO;

FramerBcQdZ8fGO.displayName = "Arrow Button";

FramerBcQdZ8fGO.defaultProps = {height: 64, width: 280};

addPropertyControls(FramerBcQdZ8fGO, {orsSeNoum: {defaultValue: "Explore all services", displayTextArea: false, title: "Text", type: ControlType.String}, pU9NaLDBf: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerBcQdZ8fGO, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})